<template>
  <div class="row">
    <div class="col-12">
      <!-- 개선 목록 -->
      <c-table
        ref="table"
        title="개선 목록"
        tableId="assessImpr"
        :columns="gridImpr.columns"
        :data="riskReduce.imprs"
        :merge="gridImpr.merge"
        :gridHeight="setheight"
        @linkClick="linkClick"
      >
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='picture'">
            <q-icon
              style="font-size: 30px; cursor:pointer;"
              class="text-primary" 
              name="image"
              @click.stop="openImprPicture(props)" />
          </template>
          <template v-else-if="col.name==='checkItemName'">
            <template v-if="props.row.checkItemId && props.row.ramCheckItemClassCd !== 'RCIC000015'">
              <!-- 작업, 기계·기구, 유해인자 & link 걸린 대상 -->
              <q-btn
                flat
                class="tableinnerBtn"
                align="left"
                color="blue-6"
                :label="props.row[col.name]"
                @click.stop="openItem(props.row)" />
            </template>
            <template v-else>
              <!-- 기타 & link 걸리지 않은 대상 -->
              {{props.row[col.name]}}
            </template>
          </template>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'checklist-impr',
  props: {
    riskReduce: {
      type: Object,
      default: function() {
        return {
          imprs: [],
          riskbooks: [],
        }
      },
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      gridImpr: {
        merge: [
          { index: 0, colName: 'processCd' },
          { index: 1, colName: 'checkClassGroup' },
          { index: 2, colName: 'checkItemGroup' },
          { index: 3, colName: 'ramChecklistAssessScenarioId' },
          { index: 4, colName: 'ramChecklistAssessScenarioId' },
          { index: 5, colName: 'ramChecklistAssessScenarioId' },
          { index: 6, colName: 'ramChecklistAssessScenarioId' },
          { index: 7, colName: 'ramChecklistAssessScenarioId' },
          { index: 8, colName: 'ramChecklistAssessScenarioId' },
        ],
        columns: [
          {
            name: 'processName',
            field: 'processName',
            label: '공정',
            align: 'left',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'ramCheckItemClassName',
            field: 'ramCheckItemClassName',
            label: '분류',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'checkItemName',
            field: 'checkItemName',
            // 평가대상
            label: '평가대상',
            align: 'center',
            style: 'width:150px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'checklistQuestion',
            field: 'checklistQuestion',
            // 체크리스트 문항
            label: '체크리스트 문항',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'relationLaw',
            field: 'relationLaw',
            // 관련법규
            label: '관련법규',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            // 현재안전조치
            label: '현재안전조치',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'ramInspectResultName',
            field: 'ramInspectResultName',
            // 결과
            label: '결과',
            align: 'center',
            style: 'width:90px',
          },
          {
            name: 'picture',
            field: 'picture',
            label: '개선 전/후 사진',
            align: 'center',
            type: 'custom',
            style: 'width:60px',
            sortable: false,
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            // 추가 리스크관리 계획
            label: '추가 리스크관리 계획',
            align: 'left',
            style: 'width:200px',
            sortable: false
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            type: 'link',
            style: 'width:300px',
            sortable: true,
          },
          {
            name: 'ibmStepName',
            field: 'ibmStepName',
            label: '진행상태',
            align: 'center',
            style: 'width:100px',
            sortable: false
          },
          {
            name: 'improveRequest',
            field: 'improveRequest',
            // 요청부서정보
            label: '요청부서정보',
            align: 'center',
            style: 'width:220px',
            sortable: true,
          },
          {
            name: 'actionCompleteRequestDate',
            field: 'actionCompleteRequestDate',
            // 조치완료요청일
            label: '조치완료요청일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            // 조치부서
            label: '조치부서',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ],
        height: '500px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      editable: true,
      imprData: null,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    setheight() {
      let pxRemove = this.height.replace('px', '');
      return this.height && this.height.indexOf('px') > -1 && !isNaN(pxRemove) ? (Number(pxRemove) - 50) + 'px' : '500px'
    }
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
    },
    research() {
      this.$emit('research');
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.imprData = row;
      this.popupOptions.title = '개선';
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        this.$_.extend(this.imprData, result.col1)
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        this.research();
      }
    },
    openItem(row) {
      if (row.ramCheckItemClassCd === 'RCIC000001') {
        this.popupOptions.title = '안전작업표준 상세'; // 안전작업표준 상세
        this.popupOptions.param = {
          mdmSopId: row.checkItemId,
        };
        this.popupOptions.target = () => import(`${'@/pages/mdm/pim/sopDetail.vue'}`);
      } else if (row.ramCheckItemClassCd === 'RCIC000005') {
        this.popupOptions.title = "유해위험기계기구 상세"; // 유해위험기계기구 상세
        this.popupOptions.param = {
          hhmHazardousMachineryId: row.checkItemId,
        };
        this.popupOptions.target = () => import(`${"@/pages/sop/mim/hazardEquipment/hazardEquipmentClassDetail.vue"}`);
      } else {
        this.popupOptions.title = '유해인자 상세'; // 유해인자 상세
        this.popupOptions.param = {
          hazardCd: row.checkItemId,
        };
        this.popupOptions.target = () => import(`${"@/pages/sai/wmr/harmfulFactorsDetail.vue"}`);
      }
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    openImprPicture(props) {
      this.popupOptions.title = '개선 사진'; // 개선 사진
      this.popupOptions.param = {
        ramChecklistAssessScenarioId: props.row.ramChecklistAssessScenarioId,
        sopImprovementIds: props.row.sopImprovementIds,
        disabled: this.disabled
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/checklist/action/checklistScenarioImprPicture.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeScenarioImprPicturePopup;
    },
    closeScenarioImprPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>